/* @keyframes float {
	0%, 100% {
	  transform: translateY(20px);
	}
	50% {
	  transform: translateY(-20px);
	}
  }

  .slide1 {
	animation: float 5s ease-in-out infinite;
  } */
  .textoverlay {
    display: none;
	position: absolute;
    width: max-content;
    top: -53%;
    left: 68%;
	transform: translateX(-50%);
	font-size: 24px; /* Adjust text size */
	font-weight: bold;
	color: #FFD700; /* Gold-like color */
	text-shadow: 0 0 10px rgba(255, 215, 0, 0.7); /* Subtle glowing effect */
	animation: blink 2.5s infinite; /* Call the blinking animation */
	z-index: 10; /* Ensure text is above the globe */
    font-size: 2em;

  }

  /* Blinking animation */
  @keyframes blink {
	0%, 50% {
	  opacity: 1; /* Visible */
	}
	51%, 100% {
	  opacity: 0; /* Hidden */
	}
  }


  @keyframes float {
	0%, 100% {
	  transform: translateY(20px);
	}
	50% {
	  transform: translateY(-10px);
	}
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* @keyframes pulse {
	0% {
	  box-shadow: 0 0 2px #fcc400;
	}
	50% {
	  box-shadow: 0 0 2px #fcc400;
	}
	100% {
	  box-shadow: 0 0 2px#fcc400;
	}
  } */

  .slide1 {
	/* animation: float 20s linear infinite, pulse 3s ease-in-out infinite; */
	animation: float 10s linear infinite;
  /* animation: rotate 10s linear infinite; */
	/* Rotates the globe continuously */
	transform-origin: center;
	border-radius: 50%;
	/* transform-origin: center; Set the rotation origin to the center of the image */
  }

.banner{
    width: 100%;
    height: calc(33em + 12vh);	/* height: auto; */
    position: relative;
    overflow: hidden;
    background: #f7f7f7;
    background: #F2F2F4;
}

/* ::selection{
	background-color: transparent;
	color: #fcc400;
} */
.slide1 {
    position: absolute;
    width: 56%;
    height: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    top: -2%;
    object-position: center;
    right: -5%;
    /* animation: shakeAnimation 6s ease-in-out infinite; Adjust duration and timing function as needed */

    /* scale: 1.1; */
    /* object-fit: cover; */
    /* object-fit: cover; */
    /* object-fit: cover;
    /* position: absolute;
    width: 75%;
    height: 100%;
    /* background-repeat: no-repeat; */
    /* object-fit: cover;
    object-position: top;
    right: 0%;
    scale: 1.1;  */
    /* object-fit: cover; */
    /* object-fit: cover;
    /* position: absolute;
    width: 40%;
    height: 100%;
    /* background-repeat: no-repeat; */
    /* object-fit: cover;
    object-position: top;
    right: 0%; */
    /* object-fit: cover;
    /* background-repeat: no-repeat; */
    /* background-size: cover;
    object-fit: cover; */
    /* object-fit: cover;
    background-size: cover; */
    /* object-fit: cover; */
    /* object-fit: cover; */

}
/*
@keyframes shakeAnimation {
    0% { transform: translateY(0); }
    25% { transform: translateY(-5px); }
    50% { transform: translateY(5px); }
    75% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
} */

/* .overlay{
    height: 100%;
    width: 100vw;
    background-color: var(--overlaycolor);
    position: absolute;
    top: 0;
} */

.content{
    margin: 0;
    position: absolute;
	top: 30%;
    left: -4%;
    /* transform: translate(-50%, -50%); */
    width: 58%;
}

.content-title{
    font-size: 42px;
    cursor:default;
    color: #fcc400;
    text-align: start;
	margin: 0px -120px 0px 120px;
}

.content-sutbtitle{
	margin: 16px -75px 0px 120px;
	color: #3D3453;
    text-align: start;
    font-size: 30px;

}


@media (max-width:660px) {
    .banner{
        width: 100%;
        height: calc(350px - 10vh);
        position: relative;
        overflow: hidden;
    }

    .slide1 {
        position: absolute;
				display: none;
        width:80%;
        height: 100%;
        /* background-repeat: no-repeat; */
        /* background-size: cover;
        object-fit: cover; */
        object-fit: cover;
        background-size: cover;

    }
    .content{
        margin: 0;
        position: absolute;
        top: 40%;
        left: -6%;
        /* transform: translate(-50%, -50%); */
        width: 80%;
    }

    .content-title{
        font-size: 15px;

    }


    .content-sutbtitle{

        font-size: 13px;
    }

}

@media(max-width:450px){
    .content{
        width: 91%;
    }

    .content-title{
			font-size: 24px;
			margin-right: -2.5%;
			margin-left: 17%;
			margin-top: -0.7rem;
			width: 93%;

	}

    .content-sutbtitle{
        font-size: 16px;
				margin-right: -2.5%;
        margin-left: 17%;
        margin-top: 6%;
        width: 93%;
			}
}

