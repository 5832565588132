
.cards-about__container{
    border-radius: 30px;
    box-shadow: 0px 1px 2.5px rgba(0,0,0,0.16);
    background: #fafafa;
    padding:25px ;
	border: 1px solid #fcc400;
}

.cards-about__container:hover{
    box-shadow:  0px  6px  4.5px rgba(252,  196,  0,  0.6);
    transition: box-shadow 0.6s;
}

.cards-about__title{
    color: #fcc400;
    font-size: 22px;
    font-weight: 500;
}

.cards-about__info{
    display: flex;
    gap: 25px;
    flex-direction: row-reverse;

}

.cards-about__info-text{
font-size: 1em;
color: #0D3453;
width: 100%;
}

.cards-about__info-img{
    width: 65px;
    height: 65px;
    position: relative;
    top: var(--card-about-img-top);
}

.cards-about__container:hover .cards-about__info-img{
    scale: 1.1;
    transition: scale 0.5s;
}

@media(max-width:450px){

	.cards-about__title{
		color: #fcc400;
		font-size: 18px;
		font-weight: bold;
		line-height: 35px;
	}

	.cards-about__info-img{
		width: 45px;
        height: 45px;
        position: absolute;
        inset-block-start: auto;
        /* top: var(--card-about-img-top); */
        margin-top: -45px;
	}
    .cards-about__container{
        padding: 15px 25px 15px 25px;
    }

    .cards-about__info-text{
        font-size: 16px;
    }

    .cards-about__info{
    gap: 10px;

    }
}
