.closeButtonContainer {
    display: flex;
    justify-content: normal;
    padding: 11px 2px;
    border-top: 1px solid #ddd;
    background-color: #fff;
    column-gap: 9px;
}

.counterControls {
	display: flex;
	align-items: center;
	justify-content: space-between;
  }

  .counterBtn {
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	padding: 5px 10px;
	cursor: pointer;
	font-size: 16px;
  }

  .counterInput {
	    width: 35px;
		height: 35px;
		text-align: center;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin: 0 5px;
		font-size: 20px;
		padding: 2px;
	
  }

  .disabledBtn {
	opacity: 0.5;
	cursor: not-allowed;
  }


  .selectedValueDisplay {
	font-size: 15px;
	color: #0d3453;
	padding: 6px 9px 0px;
	width: 92%;
  }

  .closeButton {
	background-color: #fcc400;
	color: white;
	border: none;
	padding: 6px 12px;
	border-radius: 10px;
	cursor: pointer;
	font-size: 22px;
	width: 35%;
	height: 40px;
	transition: background-color 0.2s ease;
	display: inline-flex; /* or flex, depending on your use case */
	align-items: center; /* Vertically center */
	justify-content: center; /* Horizontally center */
	text-align: center;
  }

  .closeButton:hover {
	color: black;

  }


.menuContainer {
	padding: 5px 5px 0px 10px;

  }

  .optionRow {
	display: flex;
	align-items: center; /* Align items vertically in the center */
	justify-content: space-between; /* Spread out items */
	padding: 3px 0px 4px 0px ;
	border-bottom: 1px solid #eaeaea;
  }

  .optionIcon {
	width: 35px;
    height: 35px;
    margin-right: 3px;
    margin-left: 4px;
    text-align: center;
    align-items: center;

  }

  .optionText {
	display: flex;
	flex-direction: column; /* Stack title and details vertically */
	flex: 1; /* Take up remaining space */
  }

  .optionTitle {
	font-size: 16px;
	font-weight: bold;
	color: #333;
  }

  .optionDetails {
	font-size: 12.5px;
	color: gray;
  }

  .counterControls {
	display: flex;
	align-items: center;
	direction: rtl;

  }

  .counterBtn {
	width: 35px;
	height: 35px;
	font-size:30px;
	border: 1px solid #ccc;
	background-color: #f5f5f5;
	color: #333;
	cursor: pointer;
	display: inline-flex; /* or flex, depending on your use case */
	align-items: center; /* Vertically center */
	justify-content: center; /* Horizontally center */
	text-align: center; /* Ensure text aligns center */
  }

  .counterBtn:focus,
.counterBtn:active {
  outline: none;
  box-shadow: none;
}

.counterBtn:hover {
  background: transparent; /* Prevent hover effects */
}
  .counterValue {
	margin: 0 10px;
	font-size: 16px;
	font-weight: bold;
  }


  .singleValueContainer {
	display: flex;
	align-items: center;
	gap: 10px;
  }

  .singleValueIcon {
	width: 20px;
	height: 20px;
  }

  .singleValueDetails {
	font-size: 12px;
	color: gray;
  }

.custom-option {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }

  .counter-controls {
	display: flex;
	align-items: center;
	gap: 5px;
  }

  .counter-btn {
	width: 30px;
	height: 30px;
	border: none;
	background-color: #eee;
	font-size: 16px;
	cursor: pointer;
	border-radius: 50%;
  }

  .counter-btn:hover {
	background-color: #ddd;
  }


.input-box{
	position: relative;
	width: 100%;
}

.input-box .input-label{
color: #727272;
font-size: var(--font-size-label);
font-weight: normal;
}


.input-icon{
position: absolute;
	top: 48%;
	z-index: 1;
	border-radius: 9px 0px 0px 9px;
	inset-inline-start: 0px;
	padding-top: 0px;
	border: none;
	width: auto;
	padding-left: 8px;
	padding-right: 10px;
	font-size: 14px;
	vertical-align: center;
	height: 30px;
	/* background-color: transparent; */
	text-align: center;
}

.input-icon svg{
height: 30px;
width: 34px;
fill: #9e9d9d;
}



.custom-select svg{
width: 35px;
height: 35px;
fill: #b3afaf;
}
.select-option{
display: flex;
justify-content: space-between;
position: relative;

}

.select-option::before {
content: '';
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 73%; /*should change value be smaller in mobile scrren */
height: 2px;
border-bottom: 2px dashed #BABABA;
}

.select-from , .select-to{
display: flex;
align-items: center;
justify-content: center;
gap: 5px;
width: 110px;

}

.select-container{
display: flex;
align-items: center;
gap: 10px;
}

.container-details{
display: flex;
flex-direction: column;
color: #0D3453;
gap: 0px;
line-height: 15px;
}

.container-details span{
font-size: 16px;
}
.container-details span:nth-child(2){
font-size: 13px;
}

.select-container .flag-img{
width: 35px;
height: 35px;
}

.select-from span, .select-to span  {
font-size: 17px;
}

.select-from .select-flag , .select-to .select-flag {
width: 24px;
height: 24px;
fill:#727272
}


.select-from .select-flag svg g path , .select-to .select-flag svg g path{
fill: none;
stroke: rgba(114, 114, 114, 0.8) !important;
stroke-miterlimit: 10;
stroke-width: 11px !important;
}


/* .select-from .select-flag svg path , .select-to .select-flag svg {

fill: #727272 !important;
} */


.select-goods{
	display: flex;
	gap: 20px;
	/* border-bottom: 1px solid #b5b5b58c; */
	padding: 10px;
}

.select-goods .select-name {
	font-weight: bold;
	width: auto;
}

.select-goods .select-label{
width: 85%;
}



.bouncing-loader {
	display: flex;
	justify-content: center;
	margin: 40px auto;
}

.bouncing-loader > div {
	width: 11px;
	height: 11px;
	margin: 3px 6px;
	border-radius: 50%;
	background-color: #a3a1a1;
	opacity: 1;
	animation: bouncing-loader 0.6s infinite alternate;
	-webkit-animation : bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
	to {
		opacity: 0.1;
		transform: translateY(-16px);
	}
}

/* @-webkit-keyframes  {
	to {
		opacity: 0.1;
		transform: translateY(-16px);
	}
} */


.bouncing-loader > div:nth-child(2) {
	animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
	animation-delay: 0.4s;
}

@media (max-width:1200px){

	.select-option::before {

		width: 50%; /*should change value be smaller in mobile scrren */

	}

}

@media (max-width:480px){

	.select-option::before {
		width: 15%; /*should change value be smaller in mobile scrren */
		z-index: -1;
	}

	.select-from span, .select-to span{
		font-size: 15px;
	}

	.select-from .select-flag , .select-to .select-flag {
	width: 20px;
	height: 20px;
	}

	.input-icon{
		display: none;
	}

	.custom-select svg{
		width: 25px;
		height: 25px;
	}

}
