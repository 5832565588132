
.heading-title {
	color: #fcc400;
    font-size: 24pt;
    margin-top: 20px;
    margin-bottom: 10px;
}

.heading-body{
    color: #5B5B5B;
    font-size: 19px;
    line-height: 34px;
    margin-bottom: 17px;
    word-break: break-word;
}

.under-developemnt-text{
    font-size: 25px;
    text-align: center;
    padding: 15px 0px;
    margin: 0px 10px;
    color: green;
}

@media (max-width: 780px) {

.heading-title {
    font-size: 21pt;
    margin-bottom: 20px;
}

.heading-body{
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 12px;
}

.under-developemnt-text{
    font-size: 18px;
    padding: 0px;
    margin: 0px;
    color: green;
    display: block;
    text-align: start;
}

}


@media (max-width: 560px)
{
    .heading-title {
        font-size: 16pt;
        margin-bottom: 10px;
    }



    .heading-body{
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
    }

 }
