.loader-modal{
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4) !important;
    z-index: 9999;
    transition: opacity 0.4s , visibility 0.4s;
}

.loader-modal::after{
    content: "";
    width: 65px;
    height: 65px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 10px solid;
    border-color: #fcc400 #0000;
    animation: spinner 1.5s linear infinite;
}

.loader-modal--hiden{
    opacity: 0;
    visibility: hidden;
}

@keyframes spinner {
    from {
      transform:  rotate(0deg);
    }
    to {
      transform:  rotate(360deg);
    }
  }


@media (max-width:768px) {

    .loader-modal::after{
    /* content: ""; */
    width: 40px;
    height: 40px;
    border: 8px solid;
    border-color: #fcc400 #0000;

    /* border: 9px solid #ddd;
    border-top-color: #fcc400;
    border-radius: 50%;
    animation: loading 0.5s ease-in infinite; */
}
}