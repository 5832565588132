.no-scroll {
	overflow: hidden;
  }
  .text-askManager{
text-align: justify};

.container {
	text-align: center;
}

.back-button {
  border: none;
background-color: #fff;
cursor: pointer;
	align-items: center;
	text-decoration: none;
	font-size: 40px;
	position: absolute;
	top: 90px;
	left: 2.5%;
	text-decoration: none;
	color: #fcc400;
	padding: 10px 20px;
	/* border-radius: 5px; */
	font-size: 28px;
}
.textHowContact{
	font-size: 17.6px !important;
	color: #727272 !important;
}
.back-button:hover {
	/* background-color: #fcc400; */
	border-radius: 5px;
transition: background-color 0.3s;
text-decoration: none;
align-items: center;
/* color: white; */



}

.arrow {
	font-size: 1em; /* Adjust the size of the arrow */
	margin-right: 5px; /* Add some space between the arrow and text */
}





.manager-content{
	/* padding: 12px 3% 0px 0px; */
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap:15px
}
.icon-circle{
	width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
	border: 0.5px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
	align-self: center;

}

.popup-container {
    position: fixed;
    top: 2.5em; /* Adjusted to maintain visibility */
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    text-align: start;
    overflow-y: auto; /* Added to allow vertical scrolling if content exceeds the screen */
    padding: 10px; /* Added padding for better spacing on smaller screens */
}

.popup-content {
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 100;
    height: auto; /* Adjust to content */
    max-width: 610px; /* Ensure it doesn't go beyond reasonable width on larger screens */
    width: 90%; /* Make sure it's responsive to smaller screens */
    max-height: 83vh; /* Prevent content from exceeding screen height */
    overflow-y: auto; /* Allow vertical scrolling inside the popup */
}

.popup-content::-webkit-scrollbar {
    width: 12px; /* Adjust scrollbar width */
}

.popup-content::-webkit-scrollbar-track {
	background-color: #e4e4e4;
}

.popup-content::-webkit-scrollbar-thumb {
    background-color: #fcc400; /* Thumb color */
    border-radius: 15px;
}

/* For Firefox */
.popup-content {
    scrollbar-color: #fcc400 #eaeaea; /* Thumb color Track background */
    scrollbar-width: thin; /* Set scrollbar width to thin */
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.popup-content h3 {
    margin-top: 0;
}




/* /end popup */

.add-booking{
	display: flex;
    gap: 15px;
    width: 92%;
    /* font-family: var(--font-family-dubai); */
    margin-top: 120px;
    margin-right: 4%;

}
.manager-button{
	background: rgb(252, 196, 0);
    color: white;
    width: 95%;
	margin-top: 20px;
    border: none;
    border-radius: 10px;
    height: 45px;
    text-align: center;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
	align-self: center;

}

.manager-button:hover{
	color: black;
}

.horison-card{
	width: 93%;
	/* border: 1px solid #fcc400; */
	border-radius: 10px;

}
.req-manager{
	border-radius: 10px;
    display: flex;
    flex-direction: column;
    column-gap: -27px;
    row-gap: 5px;
    border: 1px solid #fcc400;
    align-items: center;
    text-align: center;
    margin: 20px 0px 25px 0px;
	height: 26.6%;
    font-size: 18px;
	padding: 25px;

}
.same-column {
	display: flex;
	gap: 15px;
	width: 100%;
	/* font-family: var(--font-family-dubai); */
	margin-top: 40px;
	flex-direction: column; /* To stack horizontally */
  }

.horizontal-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/*background-color: #f9f9f9;  Adjust based on your design */
	padding: 15px;
	border-radius: 10px;
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
	margin-bottom: 20px; /* Ensure there's space between the card and form */
  }
  .horizontal-card  {
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-weight: bold;
  }

  .horizontal-card  {
	background-color: #ffc107;
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  }
.add-booking .booking__left{
width: 99%;
}

.add-booking .booking__right{

width: 41%;
display: flex;
flex-direction: column;
/* justify-content: space-between; */
row-gap: 0.1em;
height: initial;
}


@media (max-width: 768px) {
    .popup-container {
		top: 15px;
        right: 65px;
        justify-content: flex-start;
    }

    .popup-content {
        padding: 10px;
        width: 90%; /* Take most of the screen */
        max-width: 600px; /* Keep a reasonable width for larger screens */
        max-height: 85vh; /* Ensure it doesn't exceed screen height */
    }
}


@media (max-width:1000px){
	.req-manager {
        border-radius: 10px;
        width: 17.5em;
        height: 9em;
        font-size: 16px;
		}

}

@media(min-width:660.5px) and (max-width:800px){
	.text-askManager{
		width: auto;
	}

	.req-manager {
		width: 100%;
        border-radius: 10px;
        display: flex
;
        flex-direction: column;
        column-gap: -27px;
        row-gap: 1%;
        border: 1px solid #fcc400;
        align-items: center;
        text-align: center;
        margin: 38px 0px 70px 0px;
        height: auto;
        font-size: 1.1em;
        min-width: 20%;

}
}

html::-webkit-scrollbar {
    width: 12px;
  }

  html::-webkit-scrollbar-track {
    background-color: #e4e4e4;
  }

  html::-webkit-scrollbar-thumb {
    background-color: #FFC400;
    border-radius: 15px;
  }
	@media(max-width:660px){
		.manager-content{
			/* padding: 12px 0px 0px 0px; */
			font-size:18px;
			gap: 10px;
		}


		.req-manager {
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			column-gap: -27px;
			row-gap: 3%;
			border: 1px solid #fcc400;
			font-weight: bold;
			align-items: center;
			text-align: center;
			margin: 41px 0px 50px 0px;
			height: 17.8rem;
			width: auto;
			font-size: 18px;
		}
		.manager-button {
			background: rgb(252, 196, 0);
			color: white;
			font-weight: bold;
			width: 85%;
			border: none;
			border-radius: 10px;
			height: 50px;
			text-align: center;
			cursor: pointer;
			font-size: 25px;
			margin-top: 5px;
		}

		.popup-container {
			right: 1px;
			top: -13px;
			z-index: 1058;
				}

		.popup-content {
			padding: 1px; /* Further reduce padding */
			max-width: 95%; /* Keep it at a reasonable width */
			height: auto; /* Let the content dictate height */
			margin-right: 1.1em;

		}
		.add-booking{
			display: flex;
			flex-direction: column-reverse;
			width:100%;
			margin-right: 0%;

			}
			.add-booking .booking__left{
				width: 93%;
				}

		.add-booking .booking__right {
			width: 100%;
		}
	}


	@media(max-width:450px){
		.text-askManager{
width: max-content;
		}
		.icon-circle{
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background-color: #fff;
			border: 0.5px solid #eaeaea;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			left: var(--left-icon-manager-mobile);

		}

	}
	.manager-button:hover {
		color: black;
	}
