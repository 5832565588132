.container-counter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
  }

  .counter-controls {
	display: flex;
	align-items: center;
  }

  .counter-controls button {
	width: 30px;
	height: 30px;
	font-size: 18px;
	text-align: center;
	border: 1px solid #ccc;
	background-color: #f5f5f5;
	cursor: pointer;
  }

  .counter-controls span {
	margin: 0 10px;
	font-size: 16px;
	font-weight: bold;
  }



.card-section{
    width: 100%;
    display: flex;
    /* direction: ltr; */
}

.btn-ring:after{
	content: "";
    display: block;
    width: 45px;
    height: 45px;
    margin: -50px 40px -40px 60px;
	/* margin-right: 100px; */
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fcc400 transparent #fcc400 transparent;
    animation: ring 1.2s linear infinite;
    -webkit-animation: ring 1.2s linear infinite;
}

.btn-main:disabled {
    background: rgba(221, 221, 221, 0.5);
    border: 2px solid rgba(221, 221, 221, 0.8);
    color: #707070 ;
}



.card-inputs{
    display: flex;
    flex-grow: 1;
    gap: 10px;
    /* direction: ltr; */

}

.home-btn{
    width: 13%;
    text-align: end;
    }
    .test{
        padding-top: 20px;
    }
    .home-btn .btn-main{
     height: var(--height-input);
     width: 85%;
     border-radius: 10px;
     background-color: #fcc400;
     font-size: 1.5em;
     outline: none;
     border: none;
     color: #fff;
     font-weight: bold;
     cursor: pointer;

    }

    .home-btn .btn-main:hover{
        color: #000;
    }

    .card-section .card-inputs__selected{
        width: 100%;
     }

@media  only screen and (min-width: 800px) and (max-width: 1000px){

    .card-section{
        flex-wrap: wrap;
    }

    .card-inputs{
        flex-wrap: wrap;
        justify-content: center;
    }

    .card-section .card-inputs__selected{
       margin-bottom: 30px;
       width: 94%;
    }

    .card-section .home-btn{
        width: 94%;
        margin: 0px auto;
    }

    .card-section .home-btn .btn-main{
        width: 100%;
    }
}

@media (max-width: 800px) {

    .card-section{
        flex-direction: column;
        width: 100%;
    }
    .card-section .card-inputs{
        flex-direction: column;
    }

    .card-section .home-btn {
        width: 100%;
        margin: 30px 0px;
    }
    .card-section .home-btn .btn-main{
        width: 100%;
		font-size: 25px;
    }

}
