.home-card{
    margin: -103px 0px 30px 0px;
    display: flex;
    position: relative;
    background: rgba(178,  177,  179,  0.25);
    padding:  23px 18px;
    border-radius: 18px 18px 0px 0px;
}
.home-card_parent{
	margin: 20px 0px 200px 0px;
}

@media (max-width:850px) {

    .home-card{
        background: transparent;

    }

}


