.banner{
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    margin-top: 80px;
}

.slide1 {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-repeat: no-repeat; */
    /* background-size: cover;
    object-fit: cover; */
    /* object-fit: cover;
    background-size: cover; */
    /* object-fit: cover; */
    /* object-position: center; */
    object-fit: cover;

}

/* .overlay{
    height: 100%;
    width: 100vw;
    background-color: var(--overlaycolor);
    position: absolute;
    top: 0;
} */

.content{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 60%;
}

.content-title{
    font-size: 66px;
    color: transparent;
    cursor:default;
}


@media (max-width:660px) {
    .banner{
        width: 100%;
        height: calc(300px - 10vh);
        position: relative;
        overflow: hidden;
    }

    .slide1 {
        position: absolute;
        width: 100%;
        height: 65%;
        /* background-repeat: no-repeat; */
        /* background-size: cover;
        object-fit: cover; */
        object-fit: cover;
        background-size: cover;

    }

    .content-title{
        font-size: 20px;
    }

}

