
.slick-slide > div{
    margin: 14px 20px;
}

.slick-dots{
	margin: 0px;
}
.slick-dots li{
	margin: 0px -1px;
}

.special-parent{
    margin: auto;
    width: 90%;
}

.special-parent .slick-dots li.slick-active button:before{

    color: #fcc400;


}

.special-parent .slider-parent-special{

    border-radius: 30px;

    box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.16);
border: 1px solid #fcc400;
    background: #fafafa;
    display: flex !important;
    flex-direction: column;
    height: 225px;
}

    .slider-special-img{
     background-color: #fafafa;
     /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11); */
     margin: 0px 20px;
     border-radius: 7px;
     width: 55px;
     height: 55px;
     display: flex;
     justify-content: center;
     align-items: center;
    }

    .slide-sepcial-img-son{

        width: 70%;
        height: 70%;
    }

    .slider-special-info{
        text-align: center;
        margin: 10px 0px;
        color: #0D3453;
    }

    .slider-special-info p:first-child{
        font-size: 22px;
        font-weight: bold;
    }

    .slider-special-info p:nth-child(2){
        font-size: 16px;
        font-weight: normal;
        width: 90%;
        margin: 0px auto;
    }

    .slider-special-btn{
        border: none;
        background-color: transparent;
        outline: none;
        color: #fcc400;
        margin: 15px;
        font-size: 18px;
        /* text-align: start; */
        display: block;
        /* text-align: end; */
        border-radius: 15px;
        /* height: 57%; */
        width: 30%;
        padding: 8px;
        margin-top: auto;
        margin-inline-start: auto;

    }

    .slider-custom__dots{
      height: 10px;
      display: none;
      justify-content: center;
      align-items: center;
      gap: 5px;
      margin-top: 20px;
    }

    /* .slider-custom__dots .custom__dots{
        height: 12px;
        width: 12px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
    } */

    .main-arrow{
        cursor: pointer;
        height: 13px;
        left: 8px;
        position: relative;
        /* top: 50%; */
        transform: translateX(-50%) translateY(-16%);
        transition: transform 0.1s;
        width: 11px;
    }

    .main-arrow.previous{
        left: 13px;
    }

    .main-arrow__arrow-top, .main-arrow__arrow-bottom {
        background-color: #666;
        height: 2px;
        left: -5px;
        position: absolute;
        top: 50%;
        width: 100%;

      }


      .main-arrow__arrow-top.previous {
        transform-origin: top left;
      }
      .main-arrow__arrow-top {
        transform: rotate(45deg);
        transform-origin: bottom right;
      }

      .main-arrow__arrow-bottom {
        transform: rotate(-45deg);
        transform-origin: top right;
      }

      .main-arrow__arrow-bottom.previous {
        transform-origin: bottom left;
      }



      .main-arrow:hover {
        transform: translateX(-50%) translateY(-25%) scale(1.1);
      }


    /*______________________*/

    .slider-custom__dots .custom__middle-dots{
        height: 12px;
        width: 120px;
        background-color: #fcc400;
        border-radius: 15px;
        display: inline-block;
        direction: ltr;
    }

    @keyframes colorChange {
        from { width: 0%; }
        to { width: 100%; }
    }

      .slider-custom__dots .custom__middle-dots.active::before {
        content: "";
        display: block;
        width: 0%;
        height: 100%;
        background-color: #bbb;
        border-radius: 15px;

        animation: colorChange 0.2s linear forwards;
      }

      @media (min-width: 1000px) {

        .special-parent .slick-slider .slick-slide.slick-active.slick-current + .slick-active {
            transform: scale(1.11);
            transition: transform .3s ease-in-out;
        }

      }

    @media (max-width:605px){

        .slider-custom__dots{
            display: none;
        }

        .special-parent .slick-slider .slick-dots li button:before{
            font-size: 10px ;
        }
    }


    /* Increase the size of the dots */
    .slick-dots li button:before {
    font-size: 12px !important;  /* Default is 10px, increase for bigger dots */
    color: #FFD700; /* Change color (gold) */
    opacity: 1; /* Ensure visibility */
  }
  
  /* Active dot styling */
  .slick-dots li.slick-active button:before {
    font-size: 15px !important; /* Make active dot slightly larger */
    color: #FFA500; /* Orange color for active dot */
  }
  

    @media (max-width:450px){


		.special-parent .slider-parent-special{

	    border-radius: 20px;
		box-shadow: 0px 6px 4.5px rgba(0, 0, 0, 0.16);
		border: 1px solid #fcc400;
		background: #fafafa;
		display: flex !important;
		flex-direction: column;
		height: 248px;
		}

		.slider-special-info p:first-child{
			font-size: 18px;
			font-weight: bold;
		}
        .slider-special-btn{
            width: 50%;
        }
    }
