.form-no-resultss{
border: 1px solid #fcc400;
border-radius: 18px;
/* background-color: #fff;
box-shadow: 1.5px 3px 8px rgba(0,0,0,0.1); */
height: 100%;
width: 80%;
padding: 40px 20px;
margin: 0px auto;
}

.parent-to-from-ports{

	position: relative;
	text-align: center;
	top: -22px;
	/* left: -25em; */
	padding-left: 11px;
	padding-bottom: 20px;
	/* padding-right: 24em; */
	/* margin-top: -5px; */
	color: #0d3453;
	direction: ltr;


}

	.title-noResult{
		color: #fcc400;
		font-size: 32px;
		text-align: center;
	}

	.subttitle-noResult{
		color: #0D3453;
    margin-bottom: 1%;
    font-size: 25px;
   text-align: center;
		}

.noResult-Email{
margin-top: 25px;
;}

.noResult-phone {
	position: relative;
	top: 25px;
		}

.form-no-resultss.input-warning{
    margin-top: 9px;
    font-size: 13px;
    font-weight: bold;
    color: #F60000;
}

.form-no-resultss__check + .input-warning{
    width: 55%;
    margin: 0px auto;
}

.header-no-results{
    text-align: center;
    margin: 60px auto 30px auto;
    font-weight: bold;
    font-size: 18px;
    color: #0D3453;
    width: 60%;
}

.form-no-resultss.form-item{
    margin: var(--margin-up-down) ;
    color: #0D3453;
}

.form-no-resultss.width-100{
    width: 55% ;
    margin-left: auto;
    margin-right: auto;
}

.input-box2 .react-tel-input .special-label {
    display: none ;
  }

  .form-no-resultss__check{
    display: flex;
    align-items: center;
		justify-content: center;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    /* margin-left: auto;
    margin-right: auto; */
  }

	.noResult-only{
display: flex;
justify-content: center;
	}

.form-no-resultss__button-parent{
    align-items: center;
    justify-content: center;
		margin-top: -5px;
}

.form-no-resultss__button{
    height: var(--height-input);
		width: 47%;
    max-width: 423px;
    border-radius: 10px;
    background-color: #fcc400;
    font-size: 30px;
    outline: none;
    border: none;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin: 45px 0px 0px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.form-no-resultss__button:hover{
    color: #000;
}

.form-no-resultss__button-parent .form-no-resultss__button:disabled {
    background: rgba(221, 221, 221, 0.5);
    border: 2px solid rgba(221, 221, 221, 0.8);
    color: #707070 ;
}

.form-no-resultss__button-parent .form-no-resultss__button .btn-ring:after {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fcc400 transparent #fcc400 transparent;
    animation: ring 1.2s linear infinite;
    -webkit-animation: ring 1.2s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes  ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@media(max-width:1250px){
  .form-no-resultss__check{

    justify-content:initial;

  }
}

@media (max-width:850px){

	.noResult-name {
		position: relative;
		top: 10px;
			}

	.noResult-phone {
		position: relative;
		top: 20px;
			}

	.noResult-Email {
position: relative;
top: -4px;
	}
	.noResult-only{
position: relative;
top: 13px;
}

  .form-no-results{
    width: 100%;
    margin: 0px 0px;
    padding: 40px 40px;
    }

    .header-no-results{
        margin: 10px auto 20px auto;
        width: 100%;
        text-align: start;
    }

    .form-no-resultss.form-item{
        margin: var(--margin-up-down) ;
        color: #0D3453;
    }

    .form-no-resultss.width-100{
        width: 100% ;
    }

    .input-box2 .react-tel-input .special-label {
        display: none ;
      }



      .form-no-resultss__check + .input-warning ,.form-no-resultss__check {
        width: 100%;
        /* margin: 0px auto; */
    }

    .form-no-resultss__check{
        flex-direction: column;
        align-items: flex-start;
    }

    .form-no-resultss__button{
        width: 175px;
    }

      .home-card{
          margin: 10px 0px 30px 0px;

      }



}

@media (max-width:450px){

	.parent-to-from-ports{
font-size: 14px;
	}
	.title-noResult{
		color: #fcc400;
		font-size: 20px;
		margin-top: -19%;
		text-align: center;
	}
	.subttitle-noResult{
		color: #0D3453;
		margin-bottom: 3%;
		font-size: 16px;
		text-align: center;
	}
.form-no-resultss{
	border: 1px solid #fcc400;
	border-radius: 18px;
	/* background-color: #fff;
	box-shadow: 1.5px 3px 8px rgba(0,0,0,0.1); */
	height: 100%;
	width: 100%;
	padding: 40px 20px;
	margin: 0px auto;
	}

	.form-no-resultss__button{
		width: 100%;
	}
	.form-no-resultss__button-parent	{
		border-radius: 18px;
		/* background-color: #fff;
		box-shadow: 1.5px 3px 8px rgba(0,0,0,0.1); */
		height: 100%;
		width: 100%;
		}
}
