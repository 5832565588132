.skeleton {
    background-color: #DCDCDC !important;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  /*skeleton modal notes */
  .skeleton-number{
    width: 100px;
    height: 24px;
    margin: 12px 0px;
    border-radius: .125rem; 
  }

.skeleton-content{
    width: 95%;
    height: 22px;
    margin-bottom: 5px;
    border-radius: .125rem; 
}

/* .skeleton.width-100 {
    width: 100%;
} */

.skeleton-text {
    width: 100%;
    height: 35px;

    margin-bottom: .25rem;
    border-radius: .125rem; 
    
}

.skeleton-text2 {
    width: 30%;
    height: 35px;

    margin-bottom: .25rem;
    border-radius: .125rem; 
    
}

.skeleton-book-item {
    width: 100%;
    height: 150px;
}

.skeleton-sub-list{
    width: 100%;
    height: 68px;
    border-radius: 16px;
}



/*------------------------------------------------*/

.section-close{
    height: 140px;
    
}

.loading-error{
    background: rgb(255,119,119);
    background: linear-gradient(301deg, rgb(255, 68, 68) 0%, rgb(255, 74, 74) 1%, rgba(255,48,48,1) 1%, rgb(245, 69, 69) 30%, rgba(255,0,0,1) 100%, rgba(255,32,32,1) 100%, rgba(255,0,0,1) 100%);
    margin-top: 45px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border-radius: 73% 27% 73% 27% / 50% 50% 50% 50%;
    color: #fff;
    text-align: center;
    flex-direction: column;
}

.error-desc{
    font-size: 18px;
}


@keyframes skeleton-loading {
    50% {
        opacity: .5;
    }
}

@media (max-width: 768px) {

    .skeleton-sub-list {
        height: 66px;
    }
}

@media (max-width: 660px) {

    .skeleton-sub-list {
        height: 29px;
    }

    .section-close{
        height: 66px;
    }

    .skeleton-book-item {
        height: 80px;
    }
}

@media (max-width: 450px) {

    .skeleton-sub-list {
        height: 25px;
    }

    .loading-error {
        margin-top: 35px;
        height: 135px;
        font-size: 24px;
    }
    
    .error-desc{
        font-size: 16px;
    }
    
}